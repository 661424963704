import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavStyles = styled.div`
  .menu-controls {
    width: 37px;
    height: 100vh;
    z-index: 101;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #222c37; // #050506
    @include break(580) {
      width: 30px;
    }
  }
  nav.top-nav {
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 12px;
      margin-bottom: 25px;
      li {
        position: relative;
        a {
          position: relative;
          z-index: 101;
          width: 37px;
          height: 26px;
          display: block;
          top: 0;
          @include break(580) {
            height: 23px;
            width: 30px;
            height: 23px;
          }
          &:before {
            position: absolute;
            content: '•';
            color: rgba(255, 255, 255, 0.4);
            top: 2px;
            left: 12px;
            z-index: 101;
            pointer-events: none;
            transition: $ease;
            @include break(580) {
              left: 11px;
            }
          }
          span {
            font-family: $lg;
            font-size: 20px;
            color: #fff;
            text-transform: uppercase;
            background-color: #222c37;
            padding: 1px 6px 1px 43px;
            visibility: hidden;
            opacity: 0;
            position: relative;
            left: -100%;
            top: 0;
            z-index: 100;
            transition: $ease;
            @include break(580) {
              font-size: 14px;
              top: 2px;
              padding: 3px 4px 3px 30px;
              letter-spacing: 0.5px;
            }
          }
          &.current {
            span {
              visibility: visible;
              opacity: 1;
              left: 0;
            }
            &:before {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }
`;

export default function Nav() {
  return (
    //     <div class="menu-controls">
    //     <nav class="top-nav">
    //         <ul>
    //             <li><a class="current" href="#welcome"><span>Welcome</span></a></li>
    //             <li><a href="#about"><span>About</span></a></li>
    //             <li><a href="#gallery"><span>Gallery</span></a></li>
    //             <li><a href="#media"><span>Media</span></a></li>
    //             <li><a href="#contact"><span>Contact</span></a></li>
    //         </ul>
    //     </nav>
    // </div>
    <NavStyles>
      <div className="menu-controls">
        <nav className="top-nav">
          <ul>
            <li>
              <Link to="/" className="current">
                <span>Welcome</span>
              </Link>
            </li>
            <li>
              <Link to="/about">
                <span>About</span>
              </Link>
            </li>
            <li>
              <Link to="/featured">
                <span>Featured</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </NavStyles>
  );
}
