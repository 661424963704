// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-canceled-js": () => import("./../../../src/pages/canceled.js" /* webpackChunkName: "component---src-pages-canceled-js" */),
  "component---src-pages-dvds-suddenly-on-christmas-eve-1999-js": () => import("./../../../src/pages/dvds/suddenly-on-christmas-eve-1999.js" /* webpackChunkName: "component---src-pages-dvds-suddenly-on-christmas-eve-1999-js" */),
  "component---src-pages-dvds-suddenly-on-christmas-eve-2005-js": () => import("./../../../src/pages/dvds/suddenly-on-christmas-eve-2005.js" /* webpackChunkName: "component---src-pages-dvds-suddenly-on-christmas-eve-2005-js" */),
  "component---src-pages-dvds-suddenly-on-christmas-eve-2017-js": () => import("./../../../src/pages/dvds/suddenly-on-christmas-eve-2017.js" /* webpackChunkName: "component---src-pages-dvds-suddenly-on-christmas-eve-2017-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-templates-dvd-js": () => import("./../../../src/templates/DVD.js" /* webpackChunkName: "component---src-templates-dvd-js" */)
}

