import { createGlobalStyle } from 'styled-components';

import font from '../assets/fonts/barlowsemicondensed-regular.woff';
import fontLeague from '../assets/fonts/leaguegothic-regular.woff';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Barlow;
    src: url(${font});
  }
  @font-face {
    font-family: LeagueGothic;
    src: url(${fontLeague});
  }

  body {
    font-family: LeagueGothic, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li {
    font-family: Barlow;
    letter-spacing: 0.5px;
    color: #474747;
  }
  /* h1,h2,h3,h4,h5,h6 {
    font-family: LeagueGothic;
    font-weight: normal;
    margin: 0;
  } */

    h1 {
        font-family: LeagueGothic;
        font-size: 170px;
        font-weight: 400;
        line-height: 0.9;
        color: #FFF;
        display: block;
        margin: 0;
        text-transform: uppercase;
    }

    h2 {
        font-family: LeagueGothic;
        font-size: 60px;
        font-weight: 400;
        line-height: 1.2;
        color: #D2D7DF;
        display: block;
        margin-bottom: 38px;
        text-transform: uppercase;
        @include break(650) {
            font-size: 50px;
            margin-bottom: 18px;
        }
    }

    h3 {
        font-family: LeagueGothic;
        font-size: 39px;
        font-weight: 400;
        line-height: 46px;
        color: #FFF;
        display: block;
        text-transform: uppercase;
    }

    h4 {
        font-family: LeagueGothic;
        font-size: 26px;
        font-weight: 400;
        line-height: 31px;
        color: #FFF;
        display: block;
        text-transform: uppercase;
    }

    h5 {
        font-family: Barlow;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.3;
        color: #fff;
        display: block;
        text-transform: uppercase;
        letter-spacing: 1.1px;
    }

    h6 {
        font-family: LeagueGothic;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #000;
        display: block;
        text-transform: uppercase;
    }

    p, span, strong, em, a, ul li, ol li, small, blockquote, cite {
        font-family: Barlow;
        line-height: 1.5;
        color: #474747;
    }

  a {
    /* Chrome renders this weird with this font, so we turn it off */
    font-family: inherit;
    font-size: 15px;
    text-decoration: none;
    text-decoration-skip-ink: none;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

`;

export default Typography;
