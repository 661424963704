import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

       // Breakpoint Mixin
       @mixin break($p, $b: 'max-width'){
        @media screen and ( $b: $p + px ){
            @content;
        }
    }

  :root {
    --red: #630100;
    --black: #050506;
    --gold: #b5922c;
    --white: #fff;
    --grey: #d2d7df;
    --green: #134611;
  }

  html {
    font-size: 10px;
    /* background-color: var(--white); */
    background-color: var(--black);
  }

/* Debug */
  /* {
    outline: 1px solid #f01;
  } */

  body {
    font-size: 2rem;
    background-color: var(--white);
  }

  .wrap {
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 20px;
    }

  /* fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  } */

  button, .btn {
    background: var(--green);
    color: white;
    font-size: 2.1rem;
    /* text-transform: uppercase; */
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 3px solid transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    /* shine */
    width: 145px;
    height: 50px;
    transition: all 0.25s ease-in-out;
    @media screen and (max-width:650px) {
      width: 125px;
      height: 45px;
      padding: 0.6rem 1.2rem;
    }
    &:focus{
      outline: 0;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      background: rgba(255,255,255,0.5);
      width: 60px;
      height: 100%;
      left: 0;
      top: 0;
      opacity: .5;
      filter: blur(30px);
      transform: translateX(-100px)  skewX(-15deg);
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      background: rgba(255,255,255,0.2);
      width: 30px;
      height: 100%;
      left: 30px;
      top: 0;
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }
    &:hover{
      background: #3DA35D;
      box-shadow: 0px 3px 8px 1px rgba(0,0,0,0.35);
      cursor: pointer;
      &:before{
        transform: translateX(300px)  skewX(-15deg);  
        opacity: 0.6;
        transition: .7s;
      }
      &:after{
        transform: translateX(300px) skewX(-15deg);  
        opacity: 1;
        transition: .7s;
      }
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  .intro {
    text-align: left;
    max-width: 900px;
    margin: 0 auto 25px;
    &.last { margin-bottom: 40px; }
    @media screen and (max-width:650px) {
      font-size: 18px;
    }
  }
  
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  ul { 
    list-style-type: none;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

`;

export default GlobalStyles;
